import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useGetIdentity, useLogout } from "@refinedev/core";
import { HamburgerMenu, RefineThemedLayoutV2HeaderProps } from "@refinedev/mui";
import React, { useContext } from "react";
import { ColorModeContext } from "../../contexts/color-mode";
import { Backdrop, Badge, Button, Dialog, DialogActions, DialogContent, Menu, MenuItem, Slide } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ShoppingCart } from "@mui/icons-material";
import { CartItems } from "components/cart/cart_item";
import { TransitionProps } from "@mui/material/transitions";
import { CartContext } from "contexts/cart";

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky = true,
}) => {
  const { mode, setMode } = useContext(ColorModeContext);

  const { data: user } = useGetIdentity<IUser>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate()

  const { mutate: logout } = useLogout();

  const [backdrop, setBackDrop] = React.useState(false)
  const handleBackdrop = () => setBackDrop(!backdrop)

  const cartDetails = useContext(CartContext);

  return (
    <AppBar position={isSticky ? "sticky" : "relative"}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          <HamburgerMenu />
          <Stack
            direction="row"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              color="inherit"
              onClick={() => {
                setMode();
              }}
            >
              {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>

            {(user?.avatar || user?.name) && (
              <>
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                justifyContent="center"
              >
                <Avatar onClick={handleClick}>{user?.name.slice(0, 1)}</Avatar>
                <Badge onClick={handleBackdrop} badgeContent={cartDetails.length} color="secondary">
                  <ShoppingCart />
                </Badge>
              </Stack>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => {
                  navigate('/user')
                  handleClose()
                }}>Profile</MenuItem>
                <MenuItem onClick={() => {
                  navigate('/user/edit/'+user?.id)
                  handleClose()
                }}>Edit Profile</MenuItem>
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
              </Menu>
              <Dialog
                open={backdrop}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleBackdrop}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <CartItems />
                </DialogContent>
                <DialogActions>
                  <Button color="secondary" onClick={handleBackdrop}>Continue Shopping</Button>
                  {cartDetails.length > 0 &&
                    <Button onClick={() => {
                      navigate('/cart')
                      handleBackdrop()
                    }}>Checkout</Button>
                  }
                </DialogActions>
              </Dialog>
              </>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
