import { Backpack } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const Footer = () => {
    let navigate = useNavigate()
    return (
        <Grid style={{marginTop:20, marginBottom:20, borderTopStyle:'solid'}} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        
            <Grid item xs={4} sm={8} md={4}>
                <Typography fontFamily={'"Pacifico", "Impact"'} variant="h4" style={{cursor:'pointer', justifyContent:'center', alignItems:'center', display:'flex', marginBottom:20}} onClick={() => navigate('/')}>
                    <Backpack color="primary" style={{fontSize:30}} />BacPac.io
                </Typography>
            </Grid>
        
            <Grid item xs={2} sm={4} md={4} textAlign={'center'}>
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://bacpacio.tawk.help/" target="_blank">Help Center</a>
                   <br />
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://tawk.to/chat/65b4d0c90ff6374032c57b11/1hl54v4k7" target="_blank">Contact Us</a>
                   <br />
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://bacpacio.tawk.help/" target="_blank">Submit Ticket</a>
            </Grid>
        
            <Grid item xs={2} sm={4} md={4} textAlign={'center'}>
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://sites.google.com/view/bacpacinfo/return-policy" target="_blank">Return Policy</a>
                   <br />
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://sites.google.com/view/bacpacinfo/privacy-policy" target="_blank">Privacy Policy</a>
                   <br />
                   <a style={{textDecorationLine:'none', color:'rgb(118, 127, 135)'}} href="https://sites.google.com/view/bacpacinfo/terms-of-services" target="_blank">Terms of Services</a>
            </Grid>
        </Grid>
    )
}