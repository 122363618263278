import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Grid, Stack, Typography } from "@mui/material"
import { CartItems } from "components/cart/cart_item"
import { PublicHeader } from "components/header/public_header"
import { CreateOrder } from "components/order/create_order"
import { PurchaseOptions } from "components/products/purchase_options"
import { useState } from "react"
import { useLocation, useNavigate } from 'react-router-dom'

export const Cart = () => {
    const [confirm, setConfirm] = useState(false)

    // see if client is logged in or publicly viewing
    const location = useLocation();
    let isPublic = location.pathname.includes('public')

    let navigate = useNavigate()
    return (
        <>
        {isPublic && <PublicHeader />}
        <Container>
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
        >
            <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                <Typography variant="h6">
                    Cart Details
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{maxWidth:500}}>
                        <CartItems confirm={confirm} setConfirm={setConfirm} />
                    </div>
                </AccordionDetails>
            </Accordion>
            </div>
            <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    {isPublic ?
                        <Typography variant="h6">
                            Signin
                        </Typography>
                        :
                        <Typography variant="h6">
                            Payment Details
                        </Typography>
                    }
                </AccordionSummary>
                <AccordionDetails>
                    {isPublic ?
                        <Container>
                            <b>You must be logged in to complete this purchase.</b> 
                            <br />
                            <Button variant="contained" fullWidth onClick={() => navigate('/login?to=%2Fcart')}>Log In</Button>
                        </Container>
                        :
                        <CreateOrder confirm={confirm} setConfirm={setConfirm} />
                    }
                </AccordionDetails>
            </Accordion>
            <PurchaseOptions />       
            
        </div>
        </Stack>
        </Container>
        </>
    )
}