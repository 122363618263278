import { Add, LocalOffer, OpenInBrowser, OpenInFull, PanoramaFishEye, ViewAgenda } from "@mui/icons-material";
import { Card, CardActions, CardContent, Grid, Typography, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Select, Container } from "@mui/material";
import { useGetIdentity, useMany } from "@refinedev/core";
import { DateField } from "@refinedev/mui";
import { useEffect, useState } from "react";

export const OrderList = () => {
    const [ids, setIds] = useState<any>([]);
    const { data: user } = useGetIdentity<any>();

  const { data, isLoading, isError } = useMany<any>({
    resource: "order/find",
    ids:[],
    meta: {
      headers: {
        "token": user?.token
      }
    }
  });

  const orders = data?.data ?? [];

  const getStatus = (status_number:any) => {
    switch(status_number) {
      case '0':
        // code block
        return 'Pending'
        break;
      case '1':
        // code block
        return 'Payment Recieved Shipment Pending'
        break;
      case '2':
        // code block
        return 'Shipped, Delivery Pending'
        break;
      case '3':
        // code block
        return 'Delivered'
        break;
      default:
        // code block
        return 'Pending'
    } 
  }
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <Container style={{maxWidth:window.innerWidth - 50}}>
    <Typography variant="h4">
      Orders
    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Crypto Transaction ID</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right">View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.transactionID}
              </TableCell>
              <TableCell align="right">{row.total}</TableCell>
              <TableCell align="right">{getStatus(row.status)}</TableCell>
              <TableCell align="right"><DateField value={row.createdAt} /></TableCell>
              <TableCell align="right"><OpenInBrowser /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
}