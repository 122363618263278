import { Button, Card, CardActionArea, Container, Grid, Typography } from "@mui/material"
import { useMany } from "@refinedev/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const CategoryHeaderMenu = () => {
    const [listed_categories, setListedCategories] = useState<any>([]);
    const [singleCategory, setSingleCat] = useState<any>([]);

    let navigate = useNavigate()

    const { data, isLoading, isError } = useMany<any>({
        resource: "category/find",
        ids:[],
    });

    function getOccurrence(array:any, value:any) {
        var count = 0;
        array.forEach((v:any) => (v === value && count++));
        return count;
    }
    
    const categories = data?.data ?? [];

    useEffect(() => {
        const allCategories:any = []
        categories.filter(item => item.categories.filter((x:any) => allCategories.push(x)))
        setListedCategories(allCategories)
        setSingleCat(allCategories.reduce(function(a:any,b:any){if(a.indexOf(b)<0)a.push(b);return a;},[]))
    }, [categories])

    let images:Array<string> = ['https://storage.googleapis.com/www.upperiot.com/bacpac/home.png', 'https://storage.googleapis.com/www.upperiot.com/bacpac/hd%20tv.png', 'https://storage.googleapis.com/www.upperiot.com/bacpac/tablet.png', 'https://storage.googleapis.com/www.upperiot.com/bacpac/computer.png', ]

    return (
        <Container>

            <Container style={{margin: 10}}>
                {singleCategory.map((item:any, index:any) => 
                    <Button onClick={() => navigate('/public/categories/show/'+item)} style={{margin:5}} key={index} variant="outlined">{item+" ("+getOccurrence(listed_categories, item)+") "}</Button>
                )}
            </Container>

            {/*
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {singleCategory.map((category:any, index:any) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                    <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea onClick={() => navigate('/public/categories/show/'+category)}>
                        <img
                        style={{width:'100%'}}
                        src={images[Math.floor(Math.random() * images.length)]}
                        alt={category}
                        />
                        <Typography variant="h5" textAlign={'center'}>
                            {category+" ("+getOccurrence(listed_categories, category)+") "}
                        </Typography>
                        
                    </CardActionArea>
                    </Card>
                </Grid>
                ))}
            </Grid>
            */}
    </Container>
    )
}