import { useContext, useEffect, useState } from "react";
import { useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Card, CardContent, Chip, Container, Grid, Typography } from "@mui/material";
import { CartContext, CartDispatchContext } from "contexts/cart";
import { Add, CurrencyExchange, ExpandMore } from "@mui/icons-material";
import { PublicHeader } from "components/header/public_header";
import { Footer } from "components/footer";
import { PurchaseOptions } from "components/products/purchase_options";

export const ProductShow: React.FC = () => {

  let { id } = useParams()

  const { data, isLoading, isError } = useOne<any>({
    resource: "product",
    id,
  });

  const product = data?.data;

  const [main_image, setMainImage] = useState('')

  useEffect(() => {
    setMainImage(product?.images[0])
  }, [product])

  const handleImageChange = (image:any) => {
    setMainImage(image)
  }

  const cartDetails = useContext(CartContext);
  const setCartDetails = useContext(CartDispatchContext);
  
  const handleCart = (item:object) => {
    setCartDetails([...cartDetails, item])
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <>
    <PublicHeader />
    <Container style={{padding:30}}>
      <Typography style={{padding:30}} fontFamily={'Impact'} variant="h4">{product.title.split("-", 4).toString()}</Typography>
      <Grid style={{display:'flex', justifyContent:'space-around'}} container columns={{ xs: 4, md: 12 }}>
          <Grid item>
            <Box style={{maxWidth:500}}>
              <img style={{width:'100%'}} src={main_image} alt={product?.title.split('-')[0]+product?.title.split('-')[1]} />
              <Container>
                {
                  product?.images.map((item:any, index:any) =>
                    <img onClick={() => handleImageChange(item)} key={index} src={item} width={50} />
                  )
                }
              </Container>
            </Box>
          </Grid>
          <Grid item>
              <Box style={{maxWidth:500}}>
                <Typography variant="h4">
                  {Number(product?.price).toLocaleString('en-US', { style: 'currency', currency: 'USD', })}
                </Typography>
                <Chip label="Refurbished" variant="outlined" />
                <Typography>
                  Model : {product?.model+" | SKU : "+product?.sku}
                </Typography>
                
                {product?.categories.map((item:any) => 
                  <small key={item}>{item+" | "}</small>
                )}
                <div style={{height:30}} />
                <Typography>
                    {product?.title}
                </Typography>
                {product?.specs.map((item:any) =>
                <>
                  <div style={{height:30}} /> 
                  <Typography>
                      {Object.keys(item)+" "+Object.values(item)}
                  </Typography>
                </>
                )}
                
                <Accordion style={{marginTop:10, background:'rgba(103, 183, 247, 0.16)'}}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    AppleCare / Warranty
                  </AccordionSummary>
                  <AccordionDetails>
                    Fully backed by the manufacturer's warranty or 1 year BacPac warranty, 
                    item may have been displayed in our retail store, used occasionally for training, 
                    or returned without original packaging, accessories, and manuals. 
                    Please inspect and test the product immediately upon receipt. 
                    Apple Open Box products come with or are eligible for AppleCare.
                  </AccordionDetails>
                </Accordion>
                
                <div style={{marginTop:20, marginBottom:20, paddingTop:10, paddingBottom:10, borderTopStyle:'solid', borderBottomStyle:'solid'}}>

                  <Typography variant="h6" style={{marginBottom:5}}>
                    <b>Shop with confidence</b>
                  </Typography>
                  <Grid container>
                    <Grid item xs={2} style={{textAlign:'center'}}>
                      <CurrencyExchange style={{fontSize:"45px"}} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>
                        <b>BacPac Money Back Guarantee</b>
                      </Typography>
                      
                      <Typography>
                        Get the item you ordered or your money back. <a style={{color:'#1976D2'}} href="https://sites.google.com/view/bacpacinfo/return-policy">Learn more</a>
                      </Typography>
                    </Grid>

                  </Grid>

                </div>
                <Accordion style={{marginTop:10, background:'rgba(103, 183, 247, 0.16)'}}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    BacPac sells high quality equipment that is physically examined thoroughly 
                    before we purchase it for resale. 
                  </AccordionSummary>
                  <AccordionDetails>
                    Each product is rated by condition and includes 
                    the basic components needed for operation. All used goods come with a 180-day 
                    BacPac warranty. To ensure your satisfaction, any used equipment can be returned 
                    within 30 days of purchase, no questions asked!
                  </AccordionDetails>
                </Accordion>
              </Box>
            <Button fullWidth variant="contained" style={{marginTop:10}} startIcon={<Add />} onClick={() => handleCart(product)} size="small">Add to Cart</Button>
            <PurchaseOptions />
          </Grid>
      </Grid>
      <Footer />
    </Container>
    </>
  );
};