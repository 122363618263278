import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
  ThemedTitleV2,
} from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import {
  CategoryList,
  CategoryShow,
} from "pages/categories";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { Backpack, LocalOffer, Receipt, ShoppingCart, TurnedIn } from "@mui/icons-material";
import { ProductList, ProductShow } from "pages/products";
import { OrderList, OrderShow } from "pages/orders";
import { Cart } from "pages/cart";
import { UserEdit, UserShow } from "pages/user";
import { CartProvider } from "contexts/cart";
import { useEffect } from "react";

const customTitleHandler = ({ resource, action, params }:any) => {
  let title = "bacpac.io"; // Default title

  if (resource && action) {
    title = `BackPac.io ${action}`;
  }

  return title;
};

function App() {

  useEffect(() => {
    console.log('%cDo NOT make any changes here! Beware of scams asking you to enter any information into this window.', 'color:red; background-color:yellow; font-size:40px; font-weight:900');
    console.log = console.warn = console.error = () => {};
   }, [])
   
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <CartProvider>
              <Refine
                dataProvider={dataProvider("https://api.bacpac.io")}
                notificationProvider={notificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                resources={[
                  {
                    name: "products",
                    list: "/products",
                    show: "/products/show/:id",
                    meta: {
                      icon: <LocalOffer />
                    },
                  },
                  {
                    name: "categories",
                    list: "/categories",
                    show: "/categories/show/:id",
                    meta: {
                      icon: <TurnedIn />
                    },
                  },
                  {
                    name: "orders",
                    list: "/orders",
                    show: "/orders/show/:id",
                    meta: {
                      icon: <Receipt />
                    },
                  },
                  {
                    name: "cart",
                    list: "/cart",
                    meta: {
                      icon: <ShoppingCart />,
                      label: "Cart"
                    },
                  },
                  {
                    name: "user",
                    list: "/user",
                    show: "/user/show/:id",
                    edit: "/user/edit/:id",
                    meta: {
                      hide: true,
                    },
                  },
                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: "SP2zoy-8nmPgl-wlwDdC",
                }}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-inner"
                        fallback={<CatchAllNavigate to="/public/products" />}
                      >
                        <ThemedLayoutV2
                          Header={() => <Header isSticky={true} />}
                          Title={({ collapsed }) => (
                            <ThemedTitleV2
                              // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                              collapsed={collapsed}
                              icon={collapsed ? <Backpack /> : <Backpack />}
                              text="BacPac"
                            />
                          )}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route
                      index
                      element={<NavigateToResource resource="products" />}
                    />
                    <Route path="/products">
                      <Route index element={<ProductList />} />
                      <Route path="show/:id" element={<ProductShow />} />
                    </Route>
                    <Route path="/categories">
                      <Route index element={<CategoryList />} />
                      <Route path="show/:id" element={<CategoryShow />} />
                    </Route>
                    <Route path="/orders">
                      <Route index element={<OrderList />} />
                      <Route path="show/:id" element={<OrderShow />} />
                    </Route>
                    <Route path="/cart">
                      <Route index element={<Cart />} />
                    </Route>
                    <Route path="/user">
                      <Route index element={<UserShow />} />
                      <Route path="show/:id" element={<UserShow />} />
                      <Route path="edit/:id" element={<UserEdit />} />
                    </Route>
                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-outer"
                        fallback={<Outlet />}
                      >
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                  </Route>

                  <Route path="/public/products">
                    <Route index element={<ProductList />} />
                    <Route path="show/:id" element={<ProductShow />} />
                  </Route>
                  <Route path="/public/categories">
                    <Route index element={<CategoryList />} />
                    <Route path="show/:id" element={<CategoryShow />} />
                  </Route>
                  <Route path="/public/cart">
                    <Route index element={<Cart />} />
                  </Route>
                </Routes>

                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler handler={customTitleHandler} />
              </Refine>
            </CartProvider>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
