import React, { createContext, useEffect, useState } from "react";

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const CartContext = createContext<any>(undefined);
const CartDispatchContext = createContext<any>(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function CartProvider({ children }:any) {

    let localStoredCart = localStorage.getItem('cart')
    let tempCart = localStoredCart ? JSON.parse(localStoredCart) : []
    const [cart, setCart] = useState(tempCart);

    useEffect(() => {
      localStorage.setItem('cart', JSON.stringify(cart))
    }, [cart])
    

  return (
    <CartContext.Provider value={cart}>
      <CartDispatchContext.Provider value={setCart}>
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  );
}

export { CartProvider, CartContext, CartDispatchContext };