import { CategoryHeaderMenu } from "components/categories/category_header_menu";
import { Footer } from "components/footer";
import { PublicHeader } from "components/header/public_header";
import { useLocation } from "react-router-dom";

export const CategoryList: React.FC = () => {

  // see if client is logged in or publicly viewing
  const location = useLocation();
  let isPublic = location.pathname.includes('public')

  return (
    <>
      {isPublic && <PublicHeader />}
      <CategoryHeaderMenu />
      <Footer />
    </>
  );
};
