import { Backpack } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { AuthPage } from "@refinedev/mui";
import { useNavigate } from "react-router-dom";

export const Register = () => {
  let navigate = useNavigate()
  return <AuthPage 
            type="register" 
            title={
            <Typography fontFamily={'"Pacifico", "Impact"'} variant="h4" style={{cursor:'pointer', justifyContent:'center', alignItems:'center', display:'flex', marginBottom:20}} onClick={() => navigate('/')}>
              <Backpack color="primary" style={{fontSize:30}} />BacPac.io
            </Typography>
            } 
          />;
};
