import { Checkbox, FormControlLabel } from "@mui/material";
import { CartContext } from "contexts/cart";
import { useContext, useEffect, useState } from "react";

export const Shipping = (props:any) => {
    
    const cartDetails = useContext(CartContext);
    const [usps_priority_mail, setUSPSPriorityMail] = useState(0);
    const [usps_priority_mail_express, setUSPSPriorityMailExpress] = useState(0);
    const [mail, setMail] = useState(false)
    const [express, setExpress] = useState(false)

    useEffect(() => {
        let reg:any = []
        let express:any = []
      cartDetails.forEach((element:any) => {
        if (element.title.includes('refrigerator')) {
            reg.push(300)
            express.push(750)
        } 
        if (element.title.includes('TV')) {
            reg.push(36.50)
            express.push(101.55)
        } else {
            reg.push(15.50)
            express.push(49.55)
        }
      });
      
      setUSPSPriorityMail(reg.reduce((a:any, b:any) => a + b, 0))
      setUSPSPriorityMailExpress(express.reduce((a:any, b:any) => a + b, 0))
    }, [cartDetails])

    const handleMail = () => {
        if(mail){
            setMail(false)
            props.setShipping(null)
        } else {
            setMail(true)
            props.setShipping(usps_priority_mail)
        }
    }

    const handleExpressMail = () => {
        if(express){
            localStorage.setItem('shipping', '')
            setExpress(false)
            props.setShipping(null)
        } else {
            setExpress(true)
            props.setShipping(usps_priority_mail_express)            
        }
    }
    
    return (
        <>
            <FormControlLabel control={<Checkbox checked={mail} onClick={handleMail}  />} label={"USPS Priority Mail 3 - 7 business days "+Number(usps_priority_mail).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }
                                        )} />
            <br />
            <FormControlLabel control={<Checkbox checked={express} onClick={handleExpressMail} />} label={"USPS Priority Mail Express 1 - 3 Business Days "+Number(usps_priority_mail_express).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }
                                        )} />
        </>
    )
}