import { Button, Container, Typography } from "@mui/material"

export const PurchaseOptions = () => {
    return (
        <>     
        <Container style={{textAlign:'center'}}>
            <b>
            Our checkout is powered by:
            </b>
            <br />
            <img src="https://storage.googleapis.com/www.upperiot.com/bacpac/coinbase_logo.png" width={80} />
            <br />
            <img src="https://storage.googleapis.com/www.upperiot.com/bacpac/coinbase_banner.jpg" width={250} />
        </Container>

        <div style={{marginTop:20, marginBottom:20, paddingBottom:10, paddingTop:10, borderTopStyle:'solid', borderBottomStyle:'solid'}}>

            <Typography style={{textAlign:'center', fontWeight:700}}>
                Other Purchase Options:
            </Typography>

        </div>

            <a href="https://instagram.com/bacpac.io/shop" target="_blank">
            <Button fullWidth variant="outlined" startIcon={<img src="https://storage.googleapis.com/www.upperiot.com/bacpac/instagram-logo.png" width={50} />}>Click here to shop with us on IG</Button>
            </a>
            <br /><br />
            <a href="https://www.facebook.com/bacpac.io" target="_blank">
            <Button fullWidth variant="outlined" startIcon={<img src="https://storage.googleapis.com/www.upperiot.com/bacpac/facebook_logo.png" width={50} />}>Click here to shop with us on FB</Button>
            </a>
        </>
    )
}