import { AccountCircle, Backpack, Login, ShoppingCart } from "@mui/icons-material"
import { Autocomplete, Badge, Button, Container, Dialog, DialogActions, DialogContent, Slide, TextField, Typography } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import { useMany } from "@refinedev/core";
import { CartItems } from "components/cart/cart_item";
import { CartContext } from "contexts/cart";
import { useContext, useState, forwardRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export const PublicHeader = () => {
    const { data, isLoading, isError } = useMany<any>({
      resource: "product/find",
      ids:[]
    });

    const products = data?.data ?? [];

    const getProductLabels = () => {
      let labels:any = []
      products.filter((item:any) => labels.push({label:item.title.replaceAll('-', '')}))
      return labels
    }
  
    const top100Films = getProductLabels()

    const [backdrop, setBackDrop] = useState(false)
    const handleBackdrop = () => setBackDrop(!backdrop)

    const navigate = useNavigate()
  
    const cartDetails = useContext(CartContext);

    // see if client is logged in or publicly viewing
    const location = useLocation();
    let isPublic = location.pathname.includes('public')
  
    return (
      <Container style={{/*position:'sticky', top:0, zIndex:1,*/ backgroundColor:'rgba(103, 183, 247, 0.16)', padding:20, maxWidth:window.innerWidth - 50}}>
        <Container>
          <Typography fontFamily={'"Pacifico", "Impact"'} variant="h4" style={{cursor:'pointer', justifyContent:'center', alignItems:'center', display:'flex', marginBottom:20}} onClick={() => navigate('/')}>
            <Backpack color="primary" style={{fontSize:30}} />BacPac.io
          </Typography>
          
          {isPublic &&
            <Container style={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center'}}>

              <span>
              <AccountCircle onClick={() => navigate('/login')} style={{fontSize:40, marginBottom:-8}} />
              <br />
              Login
              </span>

              {cartDetails.length > 0 &&
              <Slide direction="up" in={cartDetails.length > 0} mountOnEnter unmountOnExit>
              <div style={{position:'fixed', right:10, top:0, backgroundColor:'#1976D2', padding:20, color:'white', zIndex:1}}>
                <Badge onClick={handleBackdrop} badgeContent={cartDetails.length} color="secondary">
                  <ShoppingCart style={{fontSize:40}} />
                </Badge>
              </div>
              </Slide>
              }
              
            </Container>
          }
        
        </Container>
        <Dialog
            open={backdrop}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleBackdrop}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <CartItems />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleBackdrop}>Continue Shopping</Button>
                
                {cartDetails.length > 0 &&
                  <Button onClick={() => {
                  navigate('/public/cart')
                  handleBackdrop()
                }}>Checkout</Button>}
            </DialogActions>
        </Dialog>
      <Container style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:10}}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={top100Films}
          onInputChange={(e:any) => e.target.attributes[3] && navigate("/public/products/show/"+products[e.target.attributes[3]["nodeValue"]]._id)}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Search Inventory" />}
        />
      </Container>
    </Container>
    )
}