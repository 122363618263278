import { Delete } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material"
import { CartContext, CartDispatchContext } from "contexts/cart";
import { Fragment, useContext } from "react";

export const CartItems = (props:any) => {
    const cartDetails = useContext(CartContext);
    const setCartDetails = useContext(CartDispatchContext);

    const handleRemove = (item:number) => {
        cartDetails.splice(item, 1);
        console.log(cartDetails)
        setCartDetails([...cartDetails])
    }

    return(
        <Grid container spacing={2}>
            {cartDetails.map((item:any, index:any) => 
            <Fragment key={index}>
                <Grid item xs={6}>
                    <img src={item.images[0]} style={{width:'100%'}} />
                </Grid>
                <Grid item xs={6}>
                    <Typography>   
                        {item.title.split('-')[0]+"- "+item.title.split('-')[1]}
                    </Typography>
                    <Typography style={{backgroundColor:'rgba(103, 183, 247, 0.16)'}}>
                        {Number(item.price).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }
                        )}
                    </Typography>
                    {!props.confirm &&
                    <Button style={{marginTop:5}} onClick={() => handleRemove(index)} color="error" variant="contained" startIcon={<Delete />}>
                        Remove 
                    </Button>
                    }
                </Grid>
            </Fragment>
            )}
        </Grid>
    )
}