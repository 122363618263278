import { Avatar, Button, Container, Stack, TextField, Typography } from "@mui/material"
import { useGetIdentity } from "@refinedev/core";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useUpdate, useOne } from "@refinedev/core";
import { useNavigate } from "react-router-dom";

export const UserEdit = () => {

    const { data: user } = useGetIdentity<any>();

    const { data, isLoading, isError } = useOne<any>({
        resource: "user/find",
        id:user?.id,
        meta: {
            headers: {
                token: user?.token
            }
        }
    });

    const record = data?.data.others;

    const [googlePlaces, setGooglePlaces] = useState<any>()
    const [phone_number, setPhoneNumber] = useState<any>()
    const [email, setEmail] = useState<any>()

    const { mutate } = useUpdate();

    let navigate = useNavigate()

    const handleUpdate = () => {
        mutate({
            resource: 'user/'+user?.id,
            values: {
              email,
              phone_number,
              address: googlePlaces
            },
            id: '',
            meta: {
                headers: {
                    token: user?.token
                }
            }
          });
    }

    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Something went wrong!</div>;
    }
    
    return (
        <Container>
            <Stack spacing={2}>
                <div style={{display:'flex', alignSelf:'center'}}>
            <Avatar style={{width:70, height:70, fontSize:50}}>{user?.name.slice(0,1)}</Avatar>
            </div>
            <Typography textAlign={'center'} variant="h4">
                Welcome {record?.email.split('@')[0]}, now you can start buying high quality refurbished items at the lowest rate!
            </Typography>
            <div style={{height:40}}></div>
            
            <Typography textAlign={'center'} variant="h6">
                Here is what you can change
            </Typography>
            <Typography>
            Email : {record?.email}
            </Typography>
            <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined" />
            <Typography>
            Phone Number : {record?.phone_number}
            </Typography>
            <TextField value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} id="outlined-basic" label="Phone Number" variant="outlined" />
            <Typography>
            Address : {record?.address?.value?.description ? user?.address?.value?.description : 'Address'}
            </Typography>
            <GooglePlacesAutocomplete
                apiKey="AIzaSyD1QT9qxc2WZZdD-fzNuXIcO71cG8gt7ME"
                selectProps={{
                    value:googlePlaces,
                    onChange: (setGooglePlaces),
                }}
            />
            <Button onClick={() => navigate('/forgot-password')}>Change Password</Button>
            <Button onClick={handleUpdate}>Update Profile</Button>
            </Stack>
        </Container>
    )
}