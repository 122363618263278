import { Box, Button, Checkbox, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, Tab, Tabs, TextField, Typography } from "@mui/material"
import { Shipping } from "components/cart/shipping";
import { useContext, useEffect, useState } from "react"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useCreate, useGetIdentity } from "@refinedev/core";
import { CartContext } from "contexts/cart";
import axios from "axios";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const CreateOrder = (props:any) => {
    const [value, setValue] = useState(0);
    const [shipping, setShipping] = useState<any>(false)
    const [googlePlaces, setGooglePlaces] = useState<any>('')
    const [pickup, setPickup] = useState(false)
    const [subtotal, setSubtotal] = useState(0)
    const [paylink, setPaylink] = useState('')
    const [email, setEmail] = useState('')
    const [phone_number, setPhoneNumber] = useState('')

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    
    const { mutate } = useCreate();

    const { data: user } = useGetIdentity<any>();
    const cartDetails = useContext(CartContext);
    const [transaction_id, setTransId] = useState('')
    const [openPaylinkBx, setOpenPaylinkBox] = useState(false)

    useEffect(() => {
      let local_subtotal:any = []
      cartDetails.filter((item:any) => local_subtotal.push(Number(item.price)))
      setSubtotal(local_subtotal.reduce((a:any, b:any) => a + b, 0))
    }, [cartDetails])

    let final_total = (Number(subtotal)+Number(shipping)).toLocaleString('en-US', {style: 'currency', currency: 'USD', })

    const handleSubmitOrder = () => {
      props.setConfirm(true)

      // save cart

      // remove localStorage.getItem('cart')

      // create coinbase paylink
      let data = {
        "name":"BacPac.io Purchase",
        "pricing_type":"fixed_price",
        "local_price": {
          "amount": Number(subtotal)+Number(shipping),
          "currency": "USD"
        }
      }
      axios.post("https://api.commerce.coinbase.com/charges", data, {
        headers: {
          'X-CC-Api-Key': 'fa23a6d6-29d6-4b3d-9268-69baa5ec958b'
        }
      })
      .then(results => {
        let local_paylink = results.data.data.hosted_url
        let transactionID = results.data.data.id
        setTransId(transactionID)
        setPaylink(local_paylink)
        setOpenPaylinkBox(true)
        // create order with paylink and transactionID
        mutate({
          resource: "order",
          values: {
            userID: user?.id,
            cart: cartDetails,
            total: Number(subtotal)+Number(shipping),
            address : googlePlaces,
            paylink: local_paylink,
            phone_number,
            email,
            transactionID
          },
          meta: {
            headers: {
              token: user?.token
            }
          }
        });
      })
      .catch(err => alert(err+" Please try again later."))
    }
    
    return (
        <>  
            {props.confirm &&
            <div>
                Order ID : {transaction_id}
            </div>
            }
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Delivery" {...a11yProps(0)} />
                <Tab label="Pickup" {...a11yProps(1)} />
            </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Container>
                    <Typography >
                        Enter your shipping address:
                    </Typography>
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyD1QT9qxc2WZZdD-fzNuXIcO71cG8gt7ME"
                        selectProps={{
                          value:googlePlaces,
                          onChange: (setGooglePlaces),
                        }}
                      />
                      {googlePlaces &&
                        <Shipping setShipping={setShipping} />
                      }
                </Container>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Container>
                    <Typography >
                        Pickup Location:
                        <br />
                        700 S Spring St, Los Angeles, CA 90014
                    </Typography>
                    <TextField onChange={(value) => setPhoneNumber(value.target.value)} value={phone_number} placeholder="1 (000) 000 - 0000" type="phone-number" fullWidth style={{margin:5}} id="outlined-basic" label={"Text me when my pickup is ready"} variant="outlined" />
                    <TextField onChange={(e) => setEmail(e.target.value)} value={email} placeholder="email@example.com" type="email" fullWidth style={{margin:5}} id="outlined-basic" label={"Email me when my pickup is ready"} variant="outlined" />
                    <FormControlLabel control={<Checkbox checked={pickup} onClick={() => setPickup(!pickup)} />} label={"Set for pickup"} />
                </Container>
            </CustomTabPanel>
            {pickup || shipping ?
            <>
              <div>
                Shipping : {Number(shipping).toLocaleString('en-US', {style: 'currency', currency: 'USD', })}
              </div>
              <div>
                Subtotal : {subtotal.toLocaleString('en-US', {style: 'currency', currency: 'USD', })}
              </div>
              <div>
                USD Total Due : {final_total}
              </div>
              {!props.confirm &&
              <Button onClick={handleSubmitOrder} style={{marginTop:20}} fullWidth variant="contained" color="warning">
                  Confirm Order
              </Button>
              }
              <Dialog
                open={openPaylinkBx}
                onClose={() => setOpenPaylinkBox(!openPaylinkBx)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <a href={paylink}>
                    <Button variant="contained">
                      Choose Payment Method
                    </Button>
                  </a>
                </DialogContent>
              </Dialog>
            </>
            :""
            }
        </>
    )
}