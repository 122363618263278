import { useContext } from "react";
import { useMany } from "@refinedev/core";
import { Button, Card, CardActionArea, CardContent, Container, Grid, Typography } from "@mui/material";
import { Add, LocalOffer } from "@mui/icons-material";
import { CartContext, CartDispatchContext } from "contexts/cart";
import { PublicHeader } from "components/header/public_header";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "components/footer";

export const CategoryShow: React.FC = () => {
  let navigate = useNavigate()

  const { id } = useParams()

  const { data, isLoading, isError } = useMany<any>({
    resource: "product/find",
    ids:[],
    meta: {
      headers:{
        category:id
      }
    }
  });

  const products = data?.data ?? [];

  const cartDetails = useContext(CartContext);
  const setCartDetails = useContext(CartDispatchContext);
  
  const handleCart = (item:object) => {
    setCartDetails([...cartDetails, item])
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <Container>
    <PublicHeader />
    <div style={{display:'flex', justifyContent:'center', margin:20}}>
      <Button onClick={() => navigate('/public/categories')} variant="contained">View All Categories</Button>
    </div>
    <Typography variant="h5" textAlign={'center'} fontFamily={'Impact'} style={{marginBottom:20}}>
      Category : {id}
    </Typography>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {products.map((product, index) => (
        <Grid item xs={2} sm={4} md={4} key={index}>
             <Card sx={{ maxWidth: 345 }} onClick={() => navigate("/public/products/show/"+product._id)}>
              <CardActionArea>
                <img
                    style={{ width: '100%', backgroundColor:'white', padding:10 }}
                    src={product.images[0]}
                    alt={product.title}
                />
                <CardContent>
                    <Typography style={{display:'flex', alignItems:'center', backgroundColor:'rgba(103, 183, 247, 0.16)', padding:5}} variant="h5">
                        <LocalOffer style={{marginRight:5}} />{Number(product.price).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }
                                        )}
                    </Typography>
                    <Typography>
                        {product.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Refurbished
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Model: {product.model}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        SKU: {product.sku}
                    </Typography>
                </CardContent>
              </CardActionArea>
                <Button style={{margin:10}} startIcon={<Add />} onClick={() => handleCart(product)} size="small">Add to Cart</Button>

            </Card>
        </Grid>
        ))}
    </Grid>
    <Footer />
    </Container>
  );
};
